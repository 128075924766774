// src/components/FacilityModal.js
import React, { useEffect } from 'react';
import './FacilityModal.css';
import config from '../config';

const FacilityModal = ({ facility, onClose }) => {

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    return (
        <div className="modalOverlay">
            <div className="modalContent">
                <h2>Facility Details</h2>
                <button className="closeButton" onClick={onClose}>X</button>

                <p><strong>ID:</strong> {facility.id}</p>
                <p><strong>Name:</strong> {facility.name}</p>

                <div className="imageContainer">
                    {facility.images.map((image, index) => (
                        <img key={index} src={`${config.API_BASE_URL}/uploads/${image}`} alt="Facility" />
                    ))}
                </div>

                <p><strong>Unit:</strong> {facility.unit}</p>
                <p><strong>Category:</strong> {facility.category}</p>
                <p><strong>Specifications:</strong></p>
                <p className="specifications">{facility.specifications}</p>

                <p><strong>Disciplines:</strong> {facility.disciplines.join(', ')}</p>
                <p><strong>Themes:</strong> {facility.themes.join(', ')}</p>
                <p><strong>Model:</strong> {facility.model}</p>
                <p><strong>Manufacturer:</strong> {facility.manufacturer}</p>

                <p><strong>Cost Price:</strong> {facility.cost_price}</p>
                <p><strong>Selling Price:</strong> {facility.selling_price}</p>
                <p className="remarks"><strong>Remarks:</strong> {facility.remarks}</p>

                <p><strong>Shape:</strong> {facility.shape}</p>
                <p><strong>Length:</strong> {facility.length}</p>
                <p><strong>Width:</strong> {facility.width}</p>
                <p><strong>Radius:</strong> {facility.radius}</p>
                <p><strong>Dimension Length:</strong> {facility.dimension_length}</p>
                <p><strong>Dimension Width:</strong> {facility.dimension_width}</p>
                <p><strong>Dimension Height:</strong> {facility.dimension_height}</p>

            </div>
        </div>
    );
};

export default FacilityModal;