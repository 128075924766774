// src/components/Home.js
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/axios';
import './Home.css';

const Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkLoggedIn = async () => {
            try {
                const response = await api.get('/check-auth'); // 假设你有一个API端点可以检查用户是否已登录
                if (response.data.loggedIn) {
                    navigate('/dashboard');
                }
            } catch (error) {
                console.error('Error checking authentication status:', error);
            }
        };

        checkLoggedIn();
    }, [navigate]);

    return (
        <div className="authContainer">
            <h1 className="authTitle">Welcome to Facility Management</h1>
            <p className="authSubtitle">Please choose an option:</p>
            <div className="authButtons">
                <Link to="/register">
                    <button className="authButton">Register</button>
                </Link>
                <Link to="/login">
                    <button className="authButton">Login</button>
                </Link>
            </div>
        </div>
    );
};

export default Home;
