// src/components/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/axios';
import './Register.css';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: ''
    });

    const { username, email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        try {
            const res = await api.post('/register', formData);
            if (res.data) {
                navigate('/login');
            } else {
                console.error('Unexpected response:', res);
            }
        } catch (err) {
            console.error('Error during registration:', err);
        }
    };

    return (
        <div className="registerContainer">
            <form onSubmit={onSubmit} className="registerForm">
                <h1 className="registerTitle">Register</h1>
                <input
                    type="text"
                    name="username"
                    value={username}
                    onChange={onChange}
                    placeholder="Username"
                    className="registerInput"
                    required
                />
                <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Email"
                    className="registerInput"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    placeholder="Password"
                    className="registerInput"
                    required
                />
                <button type="submit" className="registerButton">Register</button>
            </form>
        </div>
    );
};

export default Register;
