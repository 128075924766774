import React, { useState, useEffect, useContext } from 'react';
import api from '../api/axios';
import AuthContext from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../config';
import './FacilityBrowser.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FacilityModal from './FacilityModal';

const FacilityBrowser = () => {
    const { token } = useContext(AuthContext);
    const { roomId } = useParams();
    const navigate = useNavigate();
    const [facilities, setFacilities] = useState([]);
    const [filteredFacilities, setFilteredFacilities] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const [filterTheme, setFilterTheme] = useState('');
    const [filterDiscipline, setFilterDiscipline] = useState('');
    const [types, setTypes] = useState([]);
    const [themes, setThemes] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [roomInfo, setRoomInfo] = useState({ name: '', description: '', length: 0, width: 0 });
    const [facilityCounts, setFacilityCounts] = useState({});
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchRoomInfo = async () => {
            try {
                const res = await api.get(`/rooms/${roomId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRoomInfo(res.data);
            } catch (error) {
                console.error('Error fetching room info:', error);
            }
        };

        fetchRoomInfo();
    }, [roomId, token]);

    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                const res = await api.get('/facilities', {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setFacilities(res.data);
                setFilteredFacilities(res.data);
                extractFilters(res.data);
            } catch (error) {
                console.error('Error fetching facilities:', error);
            }
        };
        fetchFacilities();
    }, [token]);

    useEffect(() => {
        const fetchRoomFacilities = async () => {
            try {
                const res = await api.get(`/rooms/${roomId}/facilities`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const counts = res.data.reduce((acc, facility) => {
                    acc[facility.facility_id] = (acc[facility.facility_id] || 0) + 1;
                    return acc;
                }, {});
                setFacilityCounts(counts);
            } catch (error) {
                console.error('Error fetching room facilities:', error);
            }
        };

        fetchRoomFacilities();
    }, [roomId, token]);

    const extractFilters = (data) => {
        const typesSet = new Set();
        const themesSet = new Set();
        const disciplinesSet = new Set();

        data.forEach(facility => {
            typesSet.add(facility.category);
            facility.themes.forEach(theme => themesSet.add(theme));
            facility.disciplines.forEach(discipline => disciplinesSet.add(discipline));
        });

        setTypes(Array.from(typesSet));
        setThemes(Array.from(themesSet));
        setDisciplines(Array.from(disciplinesSet));
    };

    useEffect(() => {
        filterFacilities();
    }, [searchTerm, filterType, filterTheme, filterDiscipline]);

    const filterFacilities = () => {
        let filtered = facilities;
        if (searchTerm) {
            filtered = filtered.filter(facility =>
                facility.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        if (filterType) {
            filtered = filtered.filter(facility => facility.category === filterType);
        }
        if (filterTheme) {
            filtered = filtered.filter(facility => facility.themes.includes(filterTheme));
        }
        if (filterDiscipline) {
            filtered = filtered.filter(facility => facility.disciplines.includes(filterDiscipline));
        }
        setFilteredFacilities(filtered);
    };

    const handleAddFacility = async (facilityId) => {
        try {
            const res = await api.post(`/rooms/${roomId}/facilities`, {
                facility_id: facilityId,
                x: 100,
                y: 100,
                rotation: 0  // Default rotation
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('Facility added:', res.data);
            setFacilityCounts(prevCounts => ({
                ...prevCounts,
                [facilityId]: (prevCounts[facilityId] || 0) + 1
            }));
        } catch (error) {
            console.error('Error adding facility to room:', error);
        }
    };

    const formatPrice = (price) => {
        if (price == null) return '';
        return price.toLocaleString();
    };

    const handleFacilityClick = async (id) => {
        try {
            const res = await api.get(`/facility/${id}`, { headers: { Authorization: `Bearer ${token}` } });
            setSelectedFacility(res.data);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching facility details:', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedFacility(null);
    };


    return (
        <div className="facilityBrowserContainer">
            <button className="backButton" onClick={() => navigate(-1)}>Back to Room Designer</button>
            <h2>Facility Browser / {roomInfo.name} / {roomInfo.length / 1000}m * {roomInfo.width / 1000}m / {roomInfo.description}</h2>
            <div className="filters">
                <input
                    type="text"
                    placeholder="Search by name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="searchBox"
                />
                <div className="filterGroup">
                    <div className="filterOptions">
                        <div onClick={() => setFilterType('')} className={`filterOption ${filterType === '' ? 'active' : ''}`}>All Types</div>
                        {types.map(type => (
                            <div key={type} onClick={() => setFilterType(type)} className={`filterOption ${filterType === type ? 'active' : ''}`}>{type}</div>
                        ))}
                    </div>
                </div>
                <div className="filterGroup">
                    <div className="filterOptions">
                        <div onClick={() => setFilterTheme('')} className={`filterOption ${filterTheme === '' ? 'active' : ''}`}>All Themes</div>
                        {themes.map(theme => (
                            <div key={theme} onClick={() => setFilterTheme(theme)} className={`filterOption ${filterTheme === theme ? 'active' : ''}`}>{theme}</div>
                        ))}
                    </div>
                </div>
                <div className="filterGroup">
                    <div className="filterOptions">
                        <div onClick={() => setFilterDiscipline('')} className={`filterOption ${filterDiscipline === '' ? 'active' : ''}`}>All Disciplines</div>
                        {disciplines.map(discipline => (
                            <div key={discipline} onClick={() => setFilterDiscipline(discipline)} className={`filterOption ${filterDiscipline === discipline ? 'active' : ''}`}>{discipline}</div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="facilityList">
                {filteredFacilities.length > 0 ? (
                    filteredFacilities.map(facility => (
                        <div key={facility.id} className="facilityItem">
                            <LazyLoadImage
                                src={`${config.API_BASE_URL}/uploads/${facility.image_path}`}
                                alt={facility.name}
                                effect="blur"
                                height="100px"
                                onClick={() => handleFacilityClick(facility.id)}
                            />
                            <div className="facilityName">{facility.name}</div>
                            <div className="facilityPrice">{formatPrice(facility.cost_price)}</div>
                            <div className="facilityCount">In Room: <span>{facilityCounts[facility.id] || 0}</span></div>
                            <button onClick={() => handleAddFacility(facility.id)}>Add to Room</button>
                        </div>
                    ))
                ) : (
                    <div>No facilities found</div>
                )}
            </div>
            {showModal && <FacilityModal facility={selectedFacility} onClose={closeModal} />}
        </div>
    );
};

export default FacilityBrowser;
