import React, { useState, useEffect, useContext } from 'react';
import api from '../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import config from '../config';
import AuthContext from '../context/AuthContext';
import FacilityModal from './FacilityModal';
import './Dashboard.css';

const Dashboard = () => {
    const [facilities, setFacilities] = useState([]);
    const [plans, setPlans] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { token, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [facilityRes, planRes] = await Promise.all([
                    api.get('/my_facilities', { headers: { Authorization: `Bearer ${token}` } }),
                    api.get('/plans', { headers: { Authorization: `Bearer ${token}` } })
                ]);
                setFacilities(facilityRes.data);
                setPlans(planRes.data);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                } else {
                    console.error('Error fetching dashboard data:', error);
                }
            }
        };

        if (token) {
            fetchData();
        } else {
            navigate('/login');
        }
    }, [navigate, token]);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleFacilityClick = async (id) => {
        try {
            const res = await api.get(`/facility/${id}`, { headers: { Authorization: `Bearer ${token}` } });
            setSelectedFacility(res.data);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching facility details:', error);
        }
    };

    const handlePlanClick = (planId) => {
        navigate(`/plans/${planId}/rooms`);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedFacility(null);
    };

    return (
        <div className="dashboardContainer">
            <h1>Dashboard</h1>
            <div className="addLinks">
                <Link to="/add-facility">Add Facility</Link>
                <Link to="/add-plan">Add Plan</Link>
                <button className="logoutButton" onClick={handleLogout}>Logout</button>
            </div>
            <h2>Facilities</h2>
            <ul className="facilitiesList">
                {facilities.slice(0, 6).map(facility => (
                    <li key={facility.id} className="facilitiesItem" onClick={() => handleFacilityClick(facility.id)}>
                        <img src={`${config.API_BASE_URL}/uploads/${facility.image_path}`} alt={facility.name} />
                        <p>ID: {facility.id}</p>
                        <p>{facility.name}</p>
                    </li>
                ))}
            </ul>
            <h2>Plans</h2>
            <ul className="plansList">
                {plans.map(plan => (
                    <li key={plan.id} className="plansItem" onClick={() => handlePlanClick(plan.id)}>
                        {plan.name}
                    </li>
                ))}
            </ul>
            {showModal && <FacilityModal facility={selectedFacility} onClose={closeModal} />}
        </div>
    );
};

export default Dashboard;
