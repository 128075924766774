// src/components/FacilityForm.js
import React, {useState, useEffect, useContext} from 'react';
import api from '../api/axios';
import {useNavigate} from "react-router-dom";
import AuthContext from '../context/AuthContext';

const FacilityForm = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        specifications: '',
        unit_id: '',
        model: '',
        manufacturer: '',
        category_id: '',
        disciplines: [],
        themes: [],
        shape: 'rectangle',
        length: '',
        width: '',
        radius: '',
        dimension_length: '',
        dimension_width: '',
        dimension_height: '',
        cost_price: '',
        selling_price: '',
        remarks: '',
        photos: [],
        user_id: '',  // 假设你有用户ID
        maintainer_id: '',  // 维护人ID
    });

    const [categories, setCategories] = useState([]);
    const [units, setUnits] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [themes, setThemes] = useState([]);

    useEffect(() => {
        // Fetch categories, units, disciplines, themes from the server
        const fetchData = async () => {
            try {
                const [categoryRes, unitRes, disciplineRes, themeRes] = await Promise.all([
                    api.get('/categories', { headers: { Authorization: `Bearer ${token}` } }),
                    api.get('/units', { headers: { Authorization: `Bearer ${token}` } }),
                    api.get('/disciplines', { headers: { Authorization: `Bearer ${token}` } }),
                    api.get('/themes', { headers: { Authorization: `Bearer ${token}` } }),
                ]);
                setCategories(categoryRes.data);
                setUnits(unitRes.data);
                setDisciplines(disciplineRes.data);
                setThemes(themeRes.data);

                setFormData(prevData => ({
                    ...prevData,
                    unit_id: unitRes.data.length > 0 ? unitRes.data[0].id : '',
                    category_id: categoryRes.data.length > 0 ? categoryRes.data[0].id : ''
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, photos: e.target.files });
    };

    const handleMultiSelectChange = (e) => {
        const { options } = e.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setFormData({ ...formData, [e.target.name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        for (const key in formData) {
            if (key === 'photos') {
                for (let i = 0; i < formData.photos.length; i++) {
                    data.append('photos', formData.photos[i]);
                }
            } else if (Array.isArray(formData[key])) {
                data.append(key, JSON.stringify(formData[key]));
            } else {
                data.append(key, formData[key]);
            }
        }
        try {
            await api.post('/add_facility', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });
            // alert('Facility added successfully');
            navigate('/dashboard');
        } catch (error) {
            alert('Error adding facility');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{
                height: 15
            }}></div>
            <input type="text" name="name" onChange={handleChange} placeholder="Name" required />
            <textarea name="specifications" onChange={handleChange} placeholder="Specifications" className="longText"/>
            <select name="unit_id" onChange={handleChange} value={formData.unit_id}>
                {units.map(unit => (
                    <option key={unit.id} value={unit.id}>{unit.name}</option>
                ))}
            </select>
            <input type="text" name="model" onChange={handleChange} placeholder="Model" />
            <input type="text" name="manufacturer" onChange={handleChange} placeholder="Manufacturer" />
            {/*<select name="category_id" onChange={handleChange} value={formData.category_id}>*/}
            {/*    {categories.map(category => (*/}
            {/*        <option key={category.id} value={category.id}>{category.name}</option>*/}
            {/*    ))}*/}
            {/*</select>*/}
            {/*<select name="disciplines" onChange={handleMultiSelectChange} multiple value={formData.disciplines}>*/}
            {/*    {disciplines.map(discipline => (*/}
            {/*        <option key={discipline.id} value={discipline.id}>{discipline.name}</option>*/}
            {/*    ))}*/}
            {/*</select>*/}
            {/*<select name="themes" onChange={handleMultiSelectChange} multiple value={formData.themes}>*/}
            {/*    {themes.map(theme => (*/}
            {/*        <option key={theme.id} value={theme.id}>{theme.name}</option>*/}
            {/*    ))}*/}
            {/*</select>*/}
            <select name="shape" onChange={handleChange} value={formData.shape}>
                <option value="rectangle">Rectangle</option>
                <option value="circle">Circle</option>
            </select>
            {formData.shape === 'rectangle' && (
                <>
                    <input type="number" name="length" onChange={handleChange} placeholder="Length mm" step="0.01" />
                    <input type="number" name="width" onChange={handleChange} placeholder="Width mm" step="0.01" />
                </>
            )}
            {formData.shape === 'circle' && (
                <input type="number" name="radius" onChange={handleChange} placeholder="Radius mm" step="0.01" />
            )}
            {/*<input type="number" name="dimension_length" onChange={handleChange} placeholder="3D Length mm" step="0.01" />*/}
            {/*<input type="number" name="dimension_width" onChange={handleChange} placeholder="3D Width mm" step="0.01" />*/}
            {/*<input type="number" name="dimension_height" onChange={handleChange} placeholder="3D Height mm" step="0.01" />*/}
            <input type="number" name="cost_price" onChange={handleChange} placeholder="Cost Price" step="0.01" />
            <input type="number" name="selling_price" onChange={handleChange} placeholder="Selling Price" step="0.01" />
            <textarea name="remarks" onChange={handleChange} placeholder="Remarks" className="middleText"/>
            <input type="file" name="photos" onChange={handleFileChange} multiple />
            <button type="submit">Submit</button>
        </form>
    );
};

export default FacilityForm;
