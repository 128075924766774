// src/components/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/axios';
import { jwtDecode } from 'jwt-decode';
import AuthContext from '../context/AuthContext';
import './Login.css';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState('');

    const { username, password } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setError(''); // Clear error message when user starts typing
    };

    const onSubmit = async e => {
        e.preventDefault();
        try {
            const res = await api.post('/login', formData);
            if (res.data && res.data.token) {
                const { token } = res.data;
                const decoded = jwtDecode(token);
                login(token, decoded);
                navigate('/dashboard');
            } else {
                setError('Unexpected response');
                console.error('Unexpected response:', res);
            }
        } catch (err) {
            setError('Invalid username or password');
            console.error('Error during login:', err);
        }
    };

    return (
        <div className="loginContainer">
            <form onSubmit={onSubmit} className="loginForm">
                <h1 className="loginTitle">Login</h1>
                {error && <div className="errorMessage">{error}</div>}
                <input
                    type="text"
                    name="username"
                    value={username}
                    onChange={onChange}
                    placeholder="Username"
                    className="loginInput"
                    required
                />
                <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    placeholder="Password"
                    className="loginInput"
                    required
                />
                <button type="submit" className="loginButton">Login</button>
            </form>
        </div>
    );
};

export default Login;
