import React, { useState, useEffect, useContext, useRef } from 'react';
import api from '../api/axios';
import AuthContext from '../context/AuthContext';
import Draggable from 'react-draggable';
import {Link, useNavigate, useParams} from 'react-router-dom';
import FacilityModal from './FacilityModal';
import './RoomDesigner.css';

const RoomDesigner = () => {
    const { token } = useContext(AuthContext);
    const { roomId } = useParams();
    const navigate = useNavigate();
    const [facilities, setFacilities] = useState([]);
    const [roomFacilities, setRoomFacilities] = useState([]);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [selectedFacilityId, setSelectedFacilityId] = useState(null);
    const [selectedFacilityRealId, setSelectedFacilityRealId] = useState(null);
    const [roomSize, setRoomSize] = useState({ length: 0, width: 0 });
    const [roomInfo, setRoomInfo] = useState({ name: '', description: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFacilities, setFilteredFacilities] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const dragStartPos = useRef({ x: 0, y: 0 });
    const selectedFacilityRealIdInt = useRef(1)
    const displayRate = 10

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [facilityRes, roomFacilityRes, roomRes] = await Promise.all([
                    api.get('/facilities', { headers: { Authorization: `Bearer ${token}` } }),
                    api.get(`/rooms/${roomId}/facilities`, { headers: { Authorization: `Bearer ${token}` } }),
                    api.get(`/rooms/${roomId}`, { headers: { Authorization: `Bearer ${token}` } })
                ]);
                setFacilities(facilityRes.data);
                setFilteredFacilities(facilityRes.data);
                setRoomFacilities(roomFacilityRes.data);
                setRoomSize({ length: roomRes.data.length, width: roomRes.data.width });
                setRoomInfo({ name: roomRes.data.name, description: roomRes.data.description } );
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [roomId, token]);

    useEffect(() => {
        setFilteredFacilities(
            facilities.filter(facility =>
                facility.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, facilities]);

    useEffect(() => {
        const handleKeyPress = async (e) => {
            if (e.key === 'Delete' && selectedFacilityId !== null) {
                handleDeleteFacility(selectedFacilityId);
            }
            if (e.key === ' ' && selectedFacilityRealIdInt.current !== null) {
                try {
                    const res = await api.get(`/facility/${selectedFacilityRealIdInt.current}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setSelectedFacility(res.data);
                    setShowModal(true);
                } catch (error) {
                    console.error('Error fetching facility details:', error);
                }
            }
            if (e.key === 'ArrowLeft' && selectedFacilityId !== null) {
                handleRotateFacility(selectedFacilityId, -45);
            }
            if (e.key === 'ArrowRight' && selectedFacilityId !== null) {
                handleRotateFacility(selectedFacilityId, 45);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedFacilityId, token]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFacilitySelect = (facilityId) => {
        setSelectedFacility(facilityId);
    };

    const handleAddFacility = async () => {
        try {
            const res = await api.post(`/rooms/${roomId}/facilities`, {
                facility_id: selectedFacility,
                x: 100,
                y: 100,
                rotation: 0  // Default rotation
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('API Response:', res.data); // 检查API响应数据
            setRoomFacilities(prevRoomFacilities => [...prevRoomFacilities, res.data]);
            setSelectedFacility(null);
        } catch (error) {
            console.error('Error adding facility to room:', error);
        }
    };

    const handleUpdateFacility = async (id, x, y, rotation) => {
        try {
            await api.put(`/rooms/${roomId}/facilities/${id}`, {
                x,
                y,
                rotation
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error('Error updating facility:', error);
        }
    };

    const handleRotateFacility = (id, angle) => {
        setRoomFacilities(prevRoomFacilities => {
            const updatedFacilities = prevRoomFacilities.map(facility =>
                facility.id === id ? { ...facility, rotation: (facility.rotation || 0) + angle } : facility
            );
            const updatedFacility = updatedFacilities.find(facility => facility.id === id);
            handleUpdateFacility(id, updatedFacility.x, updatedFacility.y, updatedFacility.rotation);
            return updatedFacilities;
        });
    };


    useEffect(() => {
        console.log("Room facilities updated:", roomFacilities);
    }, [roomFacilities]);

    const handleDragStart = (e, data) => {
        dragStartPos.current = { x: data.x, y: data.y };
    };

    const handleDragStop = (e, data, id, facility_id) => {
        const distanceMoved = Math.sqrt(
            Math.pow(dragStartPos.current.x - data.x, 2) +
            Math.pow(dragStartPos.current.y - data.y, 2)
        );

        setRoomFacilities(prevRoomFacilities => {
            const updatedFacilities = prevRoomFacilities.map(facility =>
                facility.id === id ? { ...facility, x: data.x, y: data.y } : facility
            );
            const updatedFacility = updatedFacilities.find(facility => facility.id === id);
            handleUpdateFacility(id, updatedFacility.x, updatedFacility.y, updatedFacility.rotation || 0);
            return updatedFacilities;
        });

        if (distanceMoved < 5) {
            setSelectedFacilityId(id);
            setSelectedFacilityRealId(facility_id);
            selectedFacilityRealIdInt.current = facility_id;
        }
    };

    const handleDeleteFacility = async (id) => {
        try {
            await api.delete(`/rooms/${roomId}/facilities/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setRoomFacilities(roomFacilities.filter(facility => facility.id !== id));
            setSelectedFacilityId(null);
        } catch (error) {
            console.error('Error deleting facility:', error);
        }
    };

    const FacilityItem = ({ facility }) => (
        <Draggable
            key={facility.id}
            position={{ x: facility.x, y: facility.y }}
            onStart={handleDragStart}
            onStop={(e, data) => handleDragStop(e, data, facility.id, facility.facility_id)}
        >
            <div
                style={{
                    position: 'absolute',
                }}
            >
                <div className={`roomFacility ${selectedFacilityId === facility.id ? 'selected' : ''}`}
                    style={{
                    width: facility.shape === 'rectangle' ? `${facility.width / displayRate}px` : `${facility.radius * 2 / displayRate}px`,
                    height: facility.shape === 'rectangle' ? `${facility.length / displayRate}px` : `${facility.radius * 2 / displayRate}px`,
                    borderRadius: facility.shape === 'circle' ? '50%' : '0',
                    transform: `rotate(${facility.rotation || 0}deg)`  // Apply rotation
                }}>
                    <span className="facilityName">{facility.name}</span>
                </div>
            </div>
        </Draggable>
    );

    return (
        <div className="roomDesignerContainer">
            <button className="backButton" onClick={() => navigate(-1)}>Back to Plan</button>
            <h2>Room Designer / {roomInfo.name} / {roomSize.length/1000}m * {roomSize.width/1000}m / {roomInfo.description.substring(0, 40)}</h2>
            <div className="facilitySelector">
                <input
                    type="text"
                    placeholder="Search facility"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <select onChange={(e) => handleFacilitySelect(e.target.value)} value={selectedFacility || ''}>
                    <option value="" disabled>Select a facility</option>
                    {filteredFacilities.map((facility) => (
                        <option key={facility.id} value={facility.id}>{facility.name}</option>
                    ))}
                </select>
                <button onClick={handleAddFacility}>Add Facility</button>
                <Link to={`/rooms/${roomId}/facility-browser`}>
                    <button>Browse Facilities</button>
                </Link>
                <Link to={`/rooms/${roomId}/facility-list`}>
                    <button>Facility List</button>
                </Link>
            </div>
            <div
                className="roomCanvas"
                style={{
                    width: `${roomSize.width / displayRate}px`,
                    height: `${roomSize.length / displayRate}px`,
                    border: '1px solid black',
                    position: 'relative'
                }}
            >
                {roomFacilities
                    .filter(facility => (facility.length && facility.width) || facility.radius)
                    .map((facility) => (
                        <FacilityItem key={facility.id} facility={facility} />
                    ))}
            </div>
            <div>
                {selectedFacilityId}, {selectedFacilityRealId}
            </div>
            {showModal && <FacilityModal facility={selectedFacility} onClose={() => setShowModal(false)} />}
        </div>
    );
};

export default RoomDesigner;
