import React, { useState, useContext, useEffect } from 'react';
import api from '../api/axios';
import AuthContext from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import './PlanRooms.css';

const PlanRoomsPage = () => {
    const { token } = useContext(AuthContext);
    const { planId } = useParams();
    const [rooms, setRooms] = useState([]);
    const [planName, setPlanName] = useState('');
    const [newRoom, setNewRoom] = useState({
        name: '',
        description: '',
        length: '',
        width: '',
    });
    const [editingRoom, setEditingRoom] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlanRooms = async () => {
            try {
                const planRes = await api.get(`/plans/${planId}`, { headers: { Authorization: `Bearer ${token}` } });
                setPlanName(planRes.data.name);
                const roomsRes = await api.get(`/plans/${planId}/rooms`, { headers: { Authorization: `Bearer ${token}` } });
                setRooms(roomsRes.data);
            } catch (error) {
                console.error('Error fetching plan rooms:', error);
            }
        };

        fetchPlanRooms();
    }, [planId, token]);

    const handleChange = (e) => {
        if (editingRoom) {
            setEditingRoom({ ...editingRoom, [e.target.name]: e.target.value });
        } else {
            setNewRoom({ ...newRoom, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingRoom) {
                const res = await api.put(`/rooms/${editingRoom.id}`, editingRoom, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRooms(rooms.map(room => (room.id === editingRoom.id ? res.data : room)));
                setEditingRoom(null);
            } else {
                const res = await api.post(`/plans/${planId}/rooms`, newRoom, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setRooms([...rooms, res.data]);
                setNewRoom({ name: '', description: '', length: '', width: '' });
            }
        } catch (error) {
            console.error('Error adding or editing training room:', error);
        }
    };

    const handleEdit = (room) => {
        setEditingRoom(room);
        setNewRoom({ name: '', description: '', length: '', width: '' });
    };

    const handleDelete = async (roomId) => {
        try {
            await api.delete(`/rooms/${roomId}`, { headers: { Authorization: `Bearer ${token}` } });
            setRooms(rooms.filter(room => room.id !== roomId));
        } catch (error) {
            console.error('Error deleting room:', error);
        }
    };

    const handleRoomClick = (roomId) => {
        navigate(`/room-designer/${roomId}`);
    };

    return (
        <div className="planRoomsContainer">
            <button className="backButton" onClick={() => navigate('/dashboard')}>Back to Dashboard</button>
            <h2>{`Rooms in ${planName}`}</h2>
            <ul className="roomsList">
                {rooms.map(room => (
                    <li key={room.id} className="roomsItem">
                        <div className='roomInfo'>
                            <p className='roomName'>{room.name}</p>
                            <p>{room.description}</p>
                            <p className='areaInfo'>{room.length/1000}m*{room.width/1000}m</p>
                        </div>
                        <div className='buttonArea'>
                            <button onClick={() => handleEdit(room)}>Edit</button>
                            {/*<button onClick={() => handleDelete(room.id)}>Delete</button>*/}
                            <button onClick={() => handleRoomClick(room.id)}>Design Room</button>
                        </div>
                    </li>
                ))}
            </ul>
            <form onSubmit={handleSubmit} className="addRoomForm">
                <input type="text" name="name" value={editingRoom ? editingRoom.name : newRoom.name} onChange={handleChange} placeholder="Room Name" required />
                <textarea name="description" value={editingRoom ? editingRoom.description : newRoom.description} onChange={handleChange} placeholder="Description" />
                <input type="number" name="length" value={editingRoom ? editingRoom.length : newRoom.length} onChange={handleChange} placeholder="Length (mm)" required />
                <input type="number" name="width" value={editingRoom ? editingRoom.width : newRoom.width} onChange={handleChange} placeholder="Width (mm)" required />
                <button type="submit">{editingRoom ? 'Update Room' : 'Add Room'}</button>
            </form>
        </div>
    );
};

export default PlanRoomsPage;
