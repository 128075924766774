import React, { useState, useEffect, useContext } from 'react';
import api from '../api/axios';
import AuthContext from '../context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import './FacilityList.css';
import FacilityModal from './FacilityModal';

const FacilityList = () => {
    const { token } = useContext(AuthContext);
    const { roomId } = useParams();
    const navigate = useNavigate();
    const [facilityList, setFacilityList] = useState([]);
    const [originalFacilityList, setOriginalFacilityList] = useState([]);
    const [totals, setTotals] = useState({ totalCost: 0, totalSellingPrice: 0 });
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                const res = await api.get(`/rooms/${roomId}/facilities`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const facilities = res.data.reduce((acc, facility) => {
                    const existingFacility = acc.find(f => f.facility_id === facility.facility_id);
                    if (existingFacility) {
                        existingFacility.quantity += 1;
                        existingFacility.subtotal_cost += facility.cost_price;
                        existingFacility.subtotal_selling_price += facility.selling_price;
                    } else {
                        acc.push({
                            ...facility,
                            quantity: 1,
                            subtotal_cost: facility.cost_price,
                            subtotal_selling_price: facility.selling_price
                        });
                    }
                    return acc;
                }, []);
                setFacilityList(facilities);
                setOriginalFacilityList(res.data); // 保留原始列表
                calculateTotals(facilities);
            } catch (error) {
                console.error('Error fetching facility list:', error);
            }
        };
        fetchFacilities();
    }, [roomId, token]);

    const calculateTotals = (facilities) => {
        const totalCost = facilities.reduce((sum, facility) => sum + facility.subtotal_cost, 0);
        const totalSellingPrice = facilities.reduce((sum, facility) => sum + facility.subtotal_selling_price, 0);
        setTotals({ totalCost, totalSellingPrice });
    };

    const handleQuantityChange = async (facilityId, newQuantity) => {
        const originalFacilities = originalFacilityList.filter(f => f.facility_id === facilityId);
        const currentQuantity = originalFacilities.length;

        if (newQuantity > currentQuantity) {
            // 增加数量
            const diff = newQuantity - currentQuantity;
            for (let i = 0; i < diff; i++) {
                try {
                    await api.post(`/rooms/${roomId}/facilities`, {
                        facility_id: facilityId,
                        x: originalFacilities[0].x,
                        y: originalFacilities[0].y,
                        rotation: originalFacilities[0].rotation
                    }, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                } catch (error) {
                    console.error('Error adding facility:', error);
                }
            }
        } else if (newQuantity < currentQuantity) {
            // 减少数量
            const diff = currentQuantity - newQuantity;
            for (let i = 0; i < diff; i++) {
                try {
                    await api.delete(`/rooms/${roomId}/facilities/${originalFacilities[i].id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                } catch (error) {
                    console.error('Error deleting facility:', error);
                }
            }
        }

        // 重新获取并设置设施列表
        const res = await api.get(`/rooms/${roomId}/facilities`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        const facilities = res.data.reduce((acc, facility) => {
            const existingFacility = acc.find(f => f.facility_id === facility.facility_id);
            if (existingFacility) {
                existingFacility.quantity += 1;
                existingFacility.subtotal_cost += facility.cost_price;
                existingFacility.subtotal_selling_price += facility.selling_price;
            } else {
                acc.push({
                    ...facility,
                    quantity: 1,
                    subtotal_cost: facility.cost_price,
                    subtotal_selling_price: facility.selling_price
                });
            }
            return acc;
        }, []);
        setFacilityList(facilities);
        setOriginalFacilityList(res.data); // 更新原始列表
        calculateTotals(facilities);
    };

    const handleDeleteFacility = async (facilityId) => {
        try {
            // 获取所有匹配的物品
            const facilitiesToDelete = originalFacilityList.filter(facility => facility.facility_id === facilityId);
            // 依次删除这些物品
            for (const facility of facilitiesToDelete) {
                await api.delete(`/rooms/${roomId}/facilities/${facility.id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            }
            // 更新列表
            setOriginalFacilityList(prevList => prevList.filter(facility => facility.facility_id !== facilityId));
            setFacilityList(prevList => {
                const updatedList = prevList.filter(facility => facility.facility_id !== facilityId);
                calculateTotals(updatedList);
                return updatedList;
            });
        } catch (error) {
            console.error('Error deleting facility:', error);
        }
    };

    const formatPrice = (price) => {
        if (price == null) return '';
        return price.toLocaleString();
    };

    const handleFacilityClick = async (id) => {
        try {
            const res = await api.get(`/facility/${id}`, { headers: { Authorization: `Bearer ${token}` } });
            setSelectedFacility(res.data);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching facility details:', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedFacility(null);
    };

    return (
        <div className="facilityListContainer">
            <button className="backButton" onClick={() => navigate(-1)}>Back to Room Designer</button>
            <h2>Facility List for Room {roomId}</h2>
            <table>
                <thead>
                <tr>
                    <th>Facility</th>
                    <th>Quantity</th>
                    <th>Cost Price</th>
                    <th>Selling Price</th>
                    <th>Subtotal Cost</th>
                    <th>Subtotal Selling Price</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {facilityList.map(facility => (
                    <tr key={facility.facility_id}>
                        <td onClick={() => handleFacilityClick(facility.facility_id)}>
                            {facility.name}
                        </td>
                        <td>
                            {(facility.length && facility.width) || facility.radius ? (
                                <span>{facility.quantity}</span>
                            ) : (
                                <input
                                    type="number"
                                    value={facility.quantity}
                                    onChange={(e) => handleQuantityChange(facility.facility_id, parseInt(e.target.value))}
                                    min="1"
                                />
                            )}
                        </td>
                        <td>{formatPrice(facility.cost_price)}</td>
                        <td>{formatPrice(facility.selling_price)}</td>
                        <td>{formatPrice(facility.subtotal_cost)}</td>
                        <td>{formatPrice(facility.subtotal_selling_price)}</td>
                        <td>
                            {(facility.length && facility.width) || facility.radius ? (
                                <span>Cannot delete</span>
                            ) : (
                                <button onClick={() => handleDeleteFacility(facility.facility_id)}>Delete</button>
                            )}
                        </td>
                    </tr>
                ))}
                <tr>
                    <td colSpan="4">Total</td>
                    <td>{formatPrice(totals.totalCost)}</td>
                    <td>{formatPrice(totals.totalSellingPrice)}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
            {showModal && <FacilityModal facility={selectedFacility} onClose={closeModal} />}
        </div>
    );
};

export default FacilityList;
