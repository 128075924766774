// src/components/PlanForm.js
import React, { useState, useContext } from 'react';
import api from '../api/axios';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import './PlanForm.css';

const PlanForm = () => {
    const navigate = useNavigate();
    const { token } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/add_plans', formData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            alert('Plan added successfully');
            navigate('/dashboard');
        } catch (error) {
            alert('Error adding plan');
        }
    };

    return (
        <div className="formContainer">
            <h2 className="formTitle">Add Plan</h2>
            <form onSubmit={handleSubmit} className="planForm">
                <input type="text" name="name" onChange={handleChange} placeholder="Name" required />
                <textarea name="description" onChange={handleChange} placeholder="Description" />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default PlanForm;
