// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import FacilityForm from './components/FacilityForm';
import PlanForm from './components/PlanForm';
import TrainingRoomForm from './components/TrainingRoomForm';
import RoomDesigner from './components/RoomDesigner';
import PlanRoomsPage from './components/PlanRoomsPage';
import FacilityList from './components/FacilityList';
import FacilityBrowser from './components/FacilityBrowser';

function App() {
    return (
        <AuthProvider>
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/add-facility" element={<FacilityForm />} />
                        <Route path="/add-plan" element={<PlanForm />} />
                        <Route path="/add-training-room" element={<TrainingRoomForm />} />
                        <Route path="/plans/:planId/rooms" element={<PlanRoomsPage />} />
                        <Route path="/room-designer/:roomId" element={<RoomDesigner />} />
                        <Route path="/rooms/:roomId/facility-list" element={<FacilityList />} />
                        <Route path="/rooms/:roomId/facility-browser" element={<FacilityBrowser />} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
