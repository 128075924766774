// src/components/TrainingRoomForm.js
import React, { useState, useEffect } from 'react';
import api from '../api/axios';

const TrainingRoomForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        area: '',
        plan_id: ''
    });

    const [plans, setPlans] = useState([]);

    useEffect(() => {
        const fetchPlans = async () => {
            const res = await api.get('/plans');
            setPlans(res.data);
        };
        fetchPlans();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/training_rooms', formData);
            alert('Training room added successfully');
        } catch (error) {
            alert('Error adding training room');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="name" onChange={handleChange} placeholder="Name" required />
            <textarea name="description" onChange={handleChange} placeholder="Description" />
            <input type="number" name="area" onChange={handleChange} placeholder="Area" required step="0.01" />
            <select name="plan_id" onChange={handleChange} value={formData.plan_id}>
                {plans.map(plan => (
                    <option key={plan.id} value={plan.id}>{plan.name}</option>
                ))}
            </select>
            <button type="submit">Submit</button>
        </form>
    );
};

export default TrainingRoomForm;
